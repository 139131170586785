import * as React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';

// markup
const TermsConditions = () => {
  return (
    <Layout pageSlug="terms-conditions">
      <SEO title="Terms &amp; Conditions" />

      <div className="row bg-grey bg-marque">
        <section className="row header">
          <div className="container">
            <div className="row content">
              <div className="col l24 m24 s24">
                <div className="box bs header-full-image">
                  <div className="page-heading">
                    <h3>Terms &amp; Conditions</h3>
                  </div>
                  <div className="inner-margin-top-minus-60"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col l16 m16 s24">
                <div className="box bs">
                  <h2>Terms &amp; Conditions</h2>
                </div>
              </div>
            </div>
            <div className="row padding-bottom-10">
              <div className="col l12 m16 s24 offset-l2 offset-m2 offset-s0">
                <div className="box bs content-block"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="row bg-purple padding-top-40 padding-bottom-60">
          <div className="container">
            <div className="row">
              <div className="col l24 m24 s24">
                <div className="box bs">
                  <h3>How can we help?</h3>
                  <div className="btn-inline">
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/about-us"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> About Us </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/schools-universities"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text">
                          {' '}
                          Schools &amp; Universities{' '}
                        </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/international-students"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> International Students </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TermsConditions;
